import React, { useEffect, useState } from 'react';

import './Portfolio.css';

 import Img1 from '../Components/Assets/gladeye-1.jpg';
 import Img2 from '../Components/Assets/dogstudio-1.jpg';
 import Img3 from '../Components/Assets/noomoagency-1.jpg';
 import Img4 from '../Components/Assets/sommet-2.jpg';
 import videoFile from '../Components/Assets/VID-20240825-WA0013.mp4';

///////////////

 import ani1 from '../Components/Assets/ani1.png'
 import ani2 from '../Components/Assets/untitled1.png';
 import ani3 from '../Components/Assets/untitled2(HoudiniFX).png';
 import ani4 from '../Components/Assets/LinkedIn_Background.png';
import videoFile1 from '../Components/Assets/Wall_Rigids_Distruction0001-0192.mov';

///////////////


import videoFile2 from '../Components/Assets/0001-0216.mp4';
import Footer from './Footer';



function Portfolio() {
  return (
<>

<div className='portfolio_section'>

<h1 className='text-center'>Our Showcase</h1>
<div id='P1'>
<video
    autoPlay
    muted
    loop
    controls={false}
    playsInline
    style={{ display: 'block' }} // Optional: Ensures the video is treated as a block element
  >
    <source src={videoFile} type="video/mp4" />
    Your browser does not support the video tag.
  </video>
  <img src={Img1} alt='Img1'id='img1'/>
  <img src={Img2} alt='Img2'/>
  <img src={Img3} alt='Img3'/>
  <img src={Img4} alt='Img4'/>
</div>


<div id='P2'>
  <img src={ani1} alt='Img1'/>
  <img src={ani2} alt='Img4'/>
  <img src={ani3} alt='Img3'/>
  <img src={ani4} alt='Img4'/>
  <video
  id='video2'
    autoPlay
    muted
    loop
    controls={false}
    playsInline
    style={{ display: 'block' }} // Optional: Ensures the video is treated as a block element
  >
    <source src={videoFile1} type="video/mp4" />
    Your browser does not support the video tag.
  </video>
</div>


<div id='P3'>
<video
  id='video3'
    autoPlay
    muted
    loop
    controls={false}
    playsInline
    style={{ display: 'block' }} // Optional: Ensures the video is treated as a block element
  >
    <source src={videoFile2} type="video/mp4" />
    Your browser does not support the video tag.
  </video>
</div>

</div>

<Footer/>
</>


  );
}

export default Portfolio;
