import React from "react";
import '../Components/Footer.css';

function Footer() {
  return (
    <div className="footers">
      <div className="containers">
        <div className="row text-center align-items-center">
          <div className="col-md-4">
            <div className="logo d-flex justify-content-center">
            <p><span>L</span>ine<span>A</span>rt</p> <p><span>S</span>tudio</p>
            </div>
            </div>
            <div className="col-md-4">
              <div className="social_media">
                <p>Instagram</p>
                <p>Linkedin</p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="address">
                <p>Pune Maharashtra - 411017</p>
              </div>
            </div>

           
        </div>
        <p className="text-center mt-5">Made With ❤️ By Studio LineArt</p>
      </div>
    </div>
  );
}

export default Footer;
