import React, { useState } from "react";
import "../Components/Contact.css";
import axios from "axios";
import Footer from "./Footer";

/* react toaster */
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Contact() {
  const [getData, setData] = useState({
    Name: "",
    Email: "",
    Mobile: "",
    Message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const data = {
      Name: getData.Name,
      Email: getData.Email,
      Mobile: getData.Mobile,
      Message: getData.Message,
    };

    if (getData.Name === "") {
      toast.error("Please enter name", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }

    if (getData.Email === "") {
      toast.error("Please enter email", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }

    if (getData.Mobile === "") {
      toast.error("Please enter mobile", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }
    if (getData.Mobile.length !== 10 ) {
        toast.error("Please enter 10 numbers", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        return;
      }

    if (getData.Message === "") {
      toast.error("Please enter message", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }
    toast.success("Data submitted", {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });

    // console.log(data)

    // alert(JSON.stringify(data)); // Display the data as a string in the alert
    axios
      .post(
        "https://sheet.best/api/sheets/ead3152d-1f54-4bf8-8eaa-c1dc57b39a70",
        data
      )
      .then((response) => {
        // console.log(response);
        setData({
          Name: "",
          Email: "",
          Mobile: "",
          Message: "",
        });
      });
  };

  return (
    <>
      <div className="contact_wrapper">
        <form onSubmit={handleSubmit}>
          <h1 className="text-center">
            Contact Us <br></br>for more information
          </h1>
          <div className="mb-5">
            <label htmlFor="Name" className="form-label">
              Full Name
            </label>
            <input
              type="text"
              className="form-control"
              id="Name"
              aria-describedby="Name"
              name="Name"
              value={getData.Name}
              onChange={handleChange}
            />
          </div>
          <div className="mb-5">
            <label htmlFor="Email" className="form-label">
              Email address
            </label>
            <input
              type="email"
              className="form-control"
              id="Email"
              aria-describedby="Email"
              name="Email"
              value={getData.Email}
              onChange={handleChange}
            />
          </div>
          <div className="mb-5">
            <label htmlFor="Mobile" className="form-label">
              Mobile Number
            </label>
            <input
              type="number"
              className="form-control"
              id="Mobile"
              aria-describedby="Mobile"
              name="Mobile"
              value={getData.Mobile}
              onChange={handleChange}
            />
          </div>
          <div className="mb-5">
            <label htmlFor="Message" className="form-label">
              Enter Your Message
            </label>
            <input
              type="text"
              className="form-control"
              id="Message"
              aria-describedby="Message"
              name="Message"
              value={getData.Message}
              onChange={handleChange}
            />
          </div>
          <button type="submit" className="btn btn-primary contact_btn_submit">
            Submit
          </button>
          <ToastContainer />
        </form>
      </div>

      <Footer />
    </>
  );
}

export default Contact;
