import React, { useEffect, useState } from "react";
import gsap from 'gsap';

import '../Components/Home.css';
import HorizontalScroll from "./HorizontalScroll";
import Ability from "./Ability";
import WhyChoose from "./WhyChoose";
import Footer from "./Footer";


import clipStudio from '../Components/Assets/clip-studio.jpg';
import adobeFlash from '../Components/Assets/adobe-flash.jpg';
import blender from '../Components/Assets/blender.png';
import photoshop from '../Components/Assets/photoshop.png';
import premier from '../Components/Assets/premiere-pro.png';
import hudini from '../Components/Assets/hudini.png';

import html from '../Components/Assets/html.jpg';
import css from '../Components/Assets/css.png';
import js from '../Components/Assets/js.png';
import bootstrap from '../Components/Assets/bootstrap.png';
import react from '../Components/Assets/react.jpg';
import shopify from '../Components/Assets/shopify.png';
import wordpress from '../Components/Assets/wordpress.jpg';
import php from '../Components/Assets/php.png';
import phpmysql from '../Components/Assets/mysql.png';

const imageData = [
    { src: clipStudio, title: "Clip Studio" },
    { src: adobeFlash, title: "Adobe Flash" },
    { src: blender, title: "Blender" },
    { src: photoshop, title: "Photoshop" },
    { src: premier, title: "Premiere Pro" },
    { src: hudini, title: "Hudini" },
    { src: html, title: "HTML" },
    { src: css, title: "CSS" },
    { src: js, title: "JavaScript" },
    { src: bootstrap, title: "Bootstrap" },
    { src: react, title: "React" },
    { src: shopify, title: "Shopify" },
    { src: wordpress, title: "WordPress" },
    { src: php, title: "PHP" },
    { src: phpmysql, title: "MySQL" },
];


 



function Home() {
  useEffect(() => {
    const tl = gsap.timeline();

    tl.to(".line span", {
      x: 0, /* Animate from off-screen right to center */
      ease: "power1.out",
      delay: 1,
      stagger: {
        amount: 0.3,
      },
    });
  }, []); // Empty dependency array to run the effect only once on mount
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

  const handleMouseMove = (e) => {
    const rect = e.target.getBoundingClientRect();
    setMousePosition({
      x: e.clientX - rect.left,
      y: e.clientY - rect.top,
    });
  };

  return (
    <>
  <div className="background-container" onMouseMove={handleMouseMove}>
      <div
        className="background-image"
        style={{
          clipPath: `circle(100px at ${mousePosition.x}px ${mousePosition.y}px)`,
        }}
      />
       <div className="container">
      <div className="line">
        <span style={{fontSize:'5rem',marginTop:"5rem"}}>Welcome</span>
      </div>
      <div className="line">
        <span style={{fontSize:'6rem'}}>to</span>
      </div>
      <div className="line">
        <span style={{fontSize:'7rem'}}>Studio LineArt</span>
      </div>
    </div>
    </div>


    <div className="intro_info">
      <div className="container">
      <div className="row d-flex">
<div className="col-md-12 d-flex gap-3 flex-column">
<h1>Transforming Visions into Reality</h1>
<p>At Studio LineArt, we are dedicated to bringing your ideas to life with a wide range of creative and technical services. Our team of skilled professionals is committed to delivering high-quality solutions tailored to meet your specific needs. Whether you’re looking to captivate audiences with stunning animations or develop a robust web presence, Studio LineArt is your go-to partner for excellence.</p>
</div>
 
</div>
      </div>
      
    </div>

    <div className="glimps">
      <HorizontalScroll/>
    </div>
   

   <div className="our_ability">
    <Ability/>
   </div>

   <div className="Choose_Wrapper">
    <WhyChoose/>
   </div>


   <div className="tool_design">
    <h1 className="text-center">Tool And Technology</h1>
<div className="row align-items-center text-center justify-content-center">
            {imageData.map((image, index) => (
                <div className="col-md-3" key={index}>
                    <div className="card_wrapper">
                        <img src={image.src} alt={image.title} className="card-img-top" />
                        <div className="card-body p-0">
                            <h5 className="card-title m-0">{image.title}</h5>
                        </div>
                    </div>
                </div>
            ))}
        </div>
</div>



   <div className="footer_container">
    <Footer/>
   </div>




    </>
  );

  
}

export default Home;
