import React, { useEffect, useState } from "react";
import Service from "./Data/Service.json";

import '../Components/Ability.css';

const imageMap = {
  "1.jpeg": require('../Components/Assets/1.jpeg'),
  "2.jpeg": require('../Components/Assets/2.jpeg'),
  "FX.jpeg": require('../Components/Assets/FX.jpeg'),
  "6.jpeg": require('../Components/Assets/6.jpeg'),
  "5.jpeg": require('../Components/Assets/5.jpeg'),
  "web-development.png": require('../Components/Assets/web-development.jpg'),
  "dynamic_web.png": require('../Components/Assets/lp.jpg'),
  "10.jpeg": require('../Components/Assets/10.jpeg'),
  "Landing-Page.png": require('../Components/Assets/dynamic-web.png'),
  // Add more mappings as needed
};

function Ability() {
  const [serData, setserData] = useState({});

  useEffect(()=>{
    setserData(Service)
    console.log(serData)
  }, [])

 
  return (
    <>
      <div className="Our_services">
        <h1 className="text-center"> The Services We Provide</h1>
        <div className="service_grid_layout">
        {Object.keys(serData).map((key) => {
        const service = serData[key];
        const imageSrc = imageMap[service.Img];
        
        return (
          <div
            key={key}
            className="service_grid_box rounded-xl"
            style={{ backgroundImage: `url(${imageSrc})` }}
          >
            <div className="service_content">
            <h4>{service.Heading}</h4>
            <p>{service.Desc}</p>
            </div>
           
          </div>
        );
      })}
        </div>
      </div>
    </>
  );
}

export default Ability;
