import React, { useEffect, useState } from "react";

import ChooseusData from "../Components/Data/Choose.json";

import '../Components/WhyChoose.css';

function WhyChoose() {
  const [ChooseData, setChooseData] = useState({});

  useState(() => {
    setChooseData(ChooseusData);
    console.log(Object.keys(ChooseusData))
  }, []);

  return (
    <>
      <div className="why_choose_us">
        <h1>Why Choose Studio LineArt?</h1>
        <div className="choose_block">
          <div className="inner_choose_block">
            <div className="choose_us_box">
                {
                    Object.keys(ChooseData).map((key)=>(
                        <div key={key} className="inner_column">
                            <h4>
                                {ChooseData[key].Heading}
                            </h4>
                            <p>
                                {ChooseData[key].Desc}
                            </p>
                        </div>
                    ))
                }
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default WhyChoose;
