import React, { useEffect, useRef } from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import gsap from 'gsap';
import Home from "../Components/Home";
import About from "./About";
import Portfolio from "./Portfolio";
import Contact from "./Contact";
// import Services from "./Services";
import '../Components/TopNavBar.css';

import '../Components/Mobile-responsive/Global.css';


function TopNavBar() {
 

  return (
    <Router>
      <div className="wrapper">
        <div className="logo_x_hamburger d-flex justify-content-between align-items-center position-relative">
          
          <div className="logo d-flex gap-2">
           {/* <p><span>S</span>tudio<span>L</span>ine</p> <p><span>S</span>tudio</p> */}
           <p><span>S</span>tudio <span>L</span>ine<span>A</span>rt</p>
          </div>
          <div className="svg_path w-100 h-100"></div>
          {/* <p>lineartstudio03@gmail.com</p> */}
        </div>
        <ul className="main-menu d-flex justify-content-center">
          <div className="li_wrapper d-flex">
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/about">About</Link>
            </li>

            <li>
              <Link to="/portfolio">Portfolio</Link>
            </li>

            <li>
              <Link to="/contact">contact</Link>
            </li>

            <li>
              {/* <Link to="/services">Services</Link> */}
            </li>

         
          </div>
        </ul>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/contact" element={<Contact />} />
          {/* <Route path="/services" element={<Services />} /> */}
        </Routes>
      </div>
    </Router>
  );
}

export default TopNavBar;
