 
import './App.css';
import TopNavBar from './Components/TopNavBar';

function App() {
  return (
    <>
    <TopNavBar/>
    </>
  );
}

export default App;
