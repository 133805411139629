import React, { useEffect } from "react";
import "../Components/HorizontalScroll.css";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { useLayoutEffect, useRef } from "react";

  

gsap.registerPlugin(ScrollTrigger);

function HorizontalScroll() {
  const component = useRef();
  const slider = useRef();

  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      let panels = gsap.utils.toArray(".panel");
      gsap.to(panels, {
        xPercent: -100 * (panels.length - 1),
        ease: "none",
        scrollTrigger: {
          trigger: slider.current,
          pin: true,
          scrub: 1,
          snap: 1 / (panels.length - 1),
          end: () => "+=" + slider.current.offsetWidth,
          markers: false,
        },
      });
    }, component);
    return () => ctx.revert();
  });

  return (
    <div className="App" ref={component}>
      <div className="firstContainer">
        <h1>Check Our Glimpses</h1>
        <div class="arrow bounce">
          <a class="fa fa-arrow-down fa-2x" href="#"></a>
        </div>
      </div>
      <div ref={slider} className="containerScroll">
        <div className="panel bg_1"></div>
        <div className="panel bg_2"></div>
        <div className="panel bg_3"></div>
        <div className="panel bg_4"></div>
      </div>
      <div className="lastContainer bg_5"></div>
    </div>
  );
}

export default HorizontalScroll;
