import React, { useState } from "react";

import "./About.css";

import placeholderImg from "./Assets/team_placeholder.jpg";
import Footer from "./Footer";

const team = [
  {
    Name: "Sandeep Bisen",
    Img: "",
    speciality: "Web Developer/Frontend Developer",
    Linkedin:"",
  },
  {
    Name: "Abhay Gade",
    Img: "",
    speciality: "2D Animation/Character Designer/Motion Gaphic Artist",
    Linkedin:"",
  },
  {
    Name: "Askhay Kale",
    Img: "",
    speciality: "FX Artist/3D Animation/3D Modelling",
    Linkedin:"",
  },
];

function About() {

  const [selectedData, setselectedData] = useState(null);

  const handleClick = (data, index) => {
    setselectedData(data)
  }

  return (
    <>
      <div className="about_wrapper">
        <h1>Studio LineArt - About Us</h1>
        <p>
          At Studio LineArt, we bring creativity and technology together to deliver
          a wide range of digital services tailored to meet your business needs.
          We specialize in:
        </p>
        <ul>
          <li>
            2D and 3D Animation: From concept to creation, our animation
            services breathe life into your ideas, creating visually stunning
            content that captivates and engages your audience.
          </li>
          <li>
            Motion Graphics and FX: Our motion graphic animations and special
            effects (FX) add dynamic elements to your visual projects, making
            your content more compelling and memorable.
          </li>
          <li>
            Character Design (2D & 3D): Whether you need character designs for
            games, animations, or promotional materials, we craft unique and
            expressive characters that stand out.
          </li>
          <li>
            Web Development: We build robust and scalable websites that are not
            only aesthetically pleasing but also user-friendly and optimized for
            performance.
          </li>
          <li>
            Landing Page Development: Our expertly designed landing pages are
            tailored to convert visitors into customers, providing a seamless
            user experience that drives results.
          </li>
          <li>
            Dynamic Website Development: We create dynamic websites that adapt
            to your content and audience, offering a personalized experience for
            each visitor.
          </li>
        </ul>

        <p>
          At Studio LineArt, our mission is to turn your vision into reality. We
          combine technical expertise with artistic innovation to deliver
          high-quality digital solutions that elevate your brand and connect
          with your audience on a deeper level. Whether you’re looking to create
          stunning visuals, develop a strong online presence, or both, LineArt
          Studio is your go-to partner for all your digital needs.
        </p>
      </div>

      <div className="team_memeber row justify-content-center align-items-center text-center pb-5 mb-5">
        {team.map((data, index) => (
          <div className="col-md-3 text-center">
          <button
            type="button"
            class="btn btn-primary custom_btn"
            data-bs-toggle="modal"
            data-bs-target="#exampleModal"
            onClick={() => handleClick(data, index)}
          >
            <div className="team_wrapper col-md-auto text-center w-100" key={index}>
              <img src={data.Img ? data.Img : placeholderImg} style={{width:"250px", height:"auto"}} />
            </div>
          </button>
          </div>
        ))}
        <div
          class="modal fade"
          id="exampleModal"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-body p-0 position-relative">
              <button
                  type="button"
                  class="btn-close position-absolute end-0 p-3 z-3"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
                {selectedData ? (
                  <div className="row h-100 align-items-center position-relative">
                    <div className="col-md-6">
                      <img src={selectedData.Img ? selectedData.Img : placeholderImg} className="w-100"/>
                      </div>
                    <div className="col-md-6">
                      <h1>{selectedData.Name}</h1>
                      <p>{selectedData.speciality}</p>
                      <p>{selectedData.Linkedin}</p>
                    </div>
                  </div>
                ) : (
                  <p>Loading...</p>
                )}
              </div>
             
            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </>
  );
}

export default About;
